
import { PAGE_SIZE } from '../constants/api'
import { fetchAPI } from './'

export const listWithdrawal = async (searchQuery, page, slug) => {
    const url = `admin/withdrawals/?search=${searchQuery}&&ordering=state&limit=${PAGE_SIZE}&offset=${(page - 1) * PAGE_SIZE}&ordering=${slug}`
    return await fetchAPI(url, 'GET')
}


export const acceptWithdrawal = async (data) => {
    return await fetchAPI('admin/withdrawals/accept/', 'POST', data)
}

export const rejectWithdrawal = async (data) => {
    return await fetchAPI('admin/withdrawals/reject/', 'POST', data)
}

export const initWithdrawal = async (data) => {
    return await fetchAPI('admin/withdrawals/init/', 'POST', data)
}
