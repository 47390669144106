import axios from "axios";

import { fetchAPI } from "./";

export const getUSDTPrice = async () => {
  return await fetchAPI(`usdt/`, "GET");
};

export const getKucoinInstrumentsPrices = () => {
  return axios.get("https://api.kucoin.com/api/v1/prices");
};
