import React, { useEffect, useState } from "react";
import { Switch, Link, Route, useLocation } from "react-router-dom";
import styled from "styled-components";

const tabs = [
    {
        path: '/console/home',
        title: 'خانه'
    },
    {
        path: '/console/users',
        title: 'کاربران'
    },
    {
        path: '/console/inventories',
        title: 'انبار'
    },
    {
        path: '/console/withdrawals',
        title: 'برداشت‌ها'
    },
    {
        path: '/console/accounting',
        title: 'حسابداری'
    },
    {
        path: '/console/traders',
        title: 'معامله گران'
    },
]

const Sidebar = (props) => {
    const location = useLocation()
    return (
        <Wrapper>
            {
                tabs.map(t => (
                    <StyledLink className={`${location.pathname === t.path ? 'active' : ''}`} to={t.path}>
                        {t.title}
                    </StyledLink>
                ))
            }
        </Wrapper>

    )
}


export default Sidebar;


const Wrapper = styled.div`
    background-color: #222;
    height: 100vh;
    width: 200px;
    right: 0;
    position: fixed;
    color: white;
    display: flex;
    flex-direction: column;
`

const StyledLink = styled(Link)`
    padding: 15px;
    margin-bottom: 10px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    font-weight: bold;

    &:hover, &.active {
        background-color: orange;
        color: #fff;
    }
`