export const PAGE_SIZE = 20;

const environments = {
    LOCAL: {
        base_url: '127.0.0.1:8000',
        copycat_url : '127.0.0.1:3001',
        is_ssl: false,
    },
    DEVELOPMENT: {
        base_url: 'stg.api.pabla.ir',
        copycat_url : 'http://127.0.0.1:3001',
        is_ssl: true,
    },
    STAGING: {
        base_url: 'stg.api.pabla.ir',
        copycat_url : '127.0.0.1:3001',
        is_ssl: true,
    },
    PRODUCTION: {
        base_url: 'api.pabla.ir',
        copycat_url : 'https://copycat.pabla.ir',
        is_ssl: true,
    },
}

export const sslHelper = (scheme = 'http', isSSL = true) => {
    return scheme + (isSSL ? 's' : '') + '://' 
}
// ********* shit to change ********* below line should be comment when its on git so if I did forget dont panic and just change  'LOCAL' to 'DEVELOPMENT'
export const REACT_APP_PUBLIC_ENVIRONMENT = process.env.REACT_APP_PUBLIC_ENVIRONMENT || 'DEVELOPMENT';
// export const REACT_APP_PUBLIC_ENVIRONMENT = process.env.REACT_APP_PUBLIC_ENVIRONMENT || 'LOCAL';
export const environment = environments[REACT_APP_PUBLIC_ENVIRONMENT];

export const BASE_URL = environment.base_url;
export const COPYCAT_URL = environment.copycat_url;
export const IS_SSL = environment.is_ssl;
export const BASE_API_V1_URL = `${sslHelper('http', IS_SSL)}${BASE_URL}/api/v1`
export const BASE_COPYCAT_API_V1 = `${COPYCAT_URL}/api/v1`
export const IMAGE_BASE_API_URL = `${sslHelper('http', IS_SSL)}${BASE_URL}`
export const BASE_WS_V1_URL = `${sslHelper('ws', IS_SSL)}${BASE_URL}/ws/v1`

export const INSTRUMENTS_PRICE_WS_URL = BASE_WS_V1_URL + '/instruments/prices/'

