
import Cookies from 'js-cookie';

export const loginUser = (token, userInfo) => (dispatch, getState) => {
  Cookies.set('token', token);
  dispatch({ type: 'LOGGED_IN_USER', payload: userInfo })
}

export const logoutUser = () => (dispatch, getState) => {
  Cookies.remove('token')
  dispatch({ type: 'PURGE_USER' })
}
