import axios from "axios";
import { BASE_COPYCAT_API_V1 } from "../constants/api";
import Cookies from 'js-cookie';


const copyCatClient = axios.create({
    baseURL: BASE_COPYCAT_API_V1,
    headers:{

    }
})

copyCatClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };

copyCatClient.interceptors.request.use(
    config => {
      const token = Cookies.get('CC-Token-access');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    
      return config;
    },
  );

  let isRefreshing = false;
  let refreshSubscribers = [];
  
  copyCatClient.interceptors.response.use(response => {
    return response;
  }, error => {
    const { config, response: { status } } = error;
    const originalRequest = config;
    
    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshTokens({
          refresh: Cookies.get('CC-Token-refresh')
        })
          .then(res => {
            const { access, refresh } = res.data;
            Cookies.set('CC-Token-access', access.token)
            Cookies.set('CC-Token-refresh', refresh.token)
            isRefreshing = false;
            onRefreshed(access.token);
          })
          .catch(err => {
            Cookies.remove('CC-Token-access');
            Cookies.remove('CC-Token-refresh');
            isRefreshing = false;
          });
      }
  
      const retryOriginalRequest = new Promise((resolve, reject) => {
        subscribeRefreshToken(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    } else {
      return Promise.reject(error);
    }
});  

const subscribeRefreshToken = (cb) => {
    refreshSubscribers.push(cb);
  }
  
const onRefreshed = (token) => {
    refreshSubscribers.map(cb => cb(token));
}

export const listTraders = async () => {
    let url = `/traders/`

    return await copyCatClient.get(url)
}

export const refreshTokens = async (refreshToken) => {
    let url = `/auth/refresh-tokens`

    return await copyCatClient.post(url, {
        "refreshToken": refreshToken.refresh
    })
}

export const logIn = async (credential) => {
    let url = `/auth/login`
    return await copyCatClient.post(url, {
        'email': credential.email,
        'password': credential.password
    })
}

export const retrieveTrader = async (username) => {
  const url = `/traders/${username}/`
  return await copyCatClient.get(url)

}

export const getTraderAssets = async (id) => {
  const url = `copycat/trader/get-trader-assets/`
  return await copyCatClient.post(url, {
    'id':[
      id
    ]
  })

}

export const subscription = async (id) => {
  const url = `copycat/trader/subscribe/`
  return await copyCatClient.post(url, {
    'id':[
      id
    ]
  })

}

export const synchronization = async (id) => {
  const url = `copycat/trader/sync/`
  return await copyCatClient.post(url, {
    'id':[
      id
    ]
  })

}

export const initialization = async (id) => {
  const url = `copycat/trader/init/`
  return await copyCatClient.post(url, {
    'id':[
      id
    ]
  })

}

export const createUser = async (credential) => {
  const url = `/auth/register/`
  return await copyCatClient.post(url, {

      ...credential,
      role: "trader"
  })
}

export const updateTrader = async (data) => {
  const url = `traders/${data.id}`
  delete data.id
  return await copyCatClient.patch(url, data)

}


