import React, { useEffect } from 'react';
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { Provider, useSelector } from 'react-redux'
import { store } from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
import MyRoutes from './routes';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


function App() {

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer 
          position="top-left"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <MyRoutes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;

