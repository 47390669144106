import React from "react";
import { Button as RBButton, Spinner } from "react-bootstrap";

export const CustomButton = ({
  onClick,
  style,
  children,
  width,
  height,
  buttonColor,
  textColor,
  text,
  icon,
  iconStyle,
  textStyle,
  disabled,
  loading,
  outline,
  className,
  ...props
}) => {
  let _style = {}
  return (
    <RBButton
      disabled={disabled}
      onClick={disabled ? () => null : onClick}
      style={{
        width: width,
        height: height,
        backgroundColor: buttonColor,
        ...style,
      }}
      className={`${className} ${_style.button} ${
        outline ? _style.outline : ""
      }`}
      {...props}
    >
      <div className={_style.wrapper}>
        {loading ? (
          <Spinner animation="border" variant="light" size="sm" />
        ) : (
          <>
            {children}
            <span
              className={_style.buttonText}
              style={{ color: textColor, ...textStyle }}
            >
              {text}
            </span>
          </>
        )}
      </div>
    </RBButton>
  );
}


// .button {
//   border: none !important;
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   background-color: #f8ad30;
//   border-radius: 6px;
//   min-height: 35px;
//   transition: all 0.2s;
//   min-width: 100px;

//   .wrapper {
//       color: #fff;
//       font-weight: 700;
//   }

//   &:hover {
//       opacity: 0.9;
//       background-color: #f8ad30;
//       transform: translateY(-5%);
//   }

//   &:active {
//       opacity: 0.9;
//       background-color: #f8ad30;

//   }

//   &:disabled {
//       opacity: 0.5;
//       background-color: #f8ad30;

//   }

//   &:focus {
//       opacity: 0.9;
//       background-color: #f8ad30;
//   }

//   .icon_style {
//       height: 30px;
//       width: 30px;
//       color: gray;
//   }
// }

// .button.outline {
//   background-color: transparent;
//   border: 1px solid #f8ad30 !important;

//   .wrapper {
//       color: #f8ad30;
//   }
// }
