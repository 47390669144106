

export const transactionsStatus = {
    0: 'در حال بررسی',
    1: 'موفق',
    2: 'ناموفق',
    3: 'لغو شده',
    4: 'در حال پردازش'
}

export const withdrawalOrderingTypes = [
    {
        key: 0,
        slug: 'created_at',
        title: 'تاریخ'
    },
    // {
    //     key: 1,
    //     slug: 'amount',
    //     title: 'مبلغ'
    // },
    {
        key: 1,
        slug: 'state',
        title: 'وضعیت'
    }
]