import {combineReducers} from 'redux'



const INITIAL_AUTH_STATE = {
  isLoggedIn: false,
  userInfo:{

  }
}

const auth = (state = INITIAL_AUTH_STATE , action) => {
  switch (action.type) {
    case 'LOGGED_IN_USER' :
      return {isLoggedIn: true, userInfo: action.payload}
    case 'PURGE_USER' :
      return INITIAL_AUTH_STATE
    default:
      return state
  }
}


export const reducers = combineReducers({
  auth
})

