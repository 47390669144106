import { fetchAPI } from './';

export const listInventories = async () => {
    return await fetchAPI(`admin/inventories/`, 'GET')
}

export const trade = async (data) => {
    return await fetchAPI('admin/instruments/trade/', 'POST', data)
}

