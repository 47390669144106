import React from "react";
import { Container, Button } from "react-bootstrap";
import styled from "styled-components";
import { CustomButton } from '../../components/customButton'
import { colors } from '../../assets/Colors'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../actions'
import { useNavigate } from "react-router-dom";

const Navbar = (props) => {
    const { pageTitle } = props
    const dispatch = useDispatch()
    let navigate = useNavigate();



    const handleLogout = () => {
        navigate('/login');
        dispatch(logoutUser())
    }
    
    return (
        <StyledNavbar>
            <StyledContainer>
                <div className='titleWrapper'>
                    <h3 className='title'>{pageTitle}</h3>
                </div>
                <CustomButton 
                  type="submit"
                  disabled={false}
                  loading={false}
                  buttonColor={colors.red}
                  onClick={handleLogout}
                  text='خروج'
                />
            </StyledContainer>
        </StyledNavbar>
    );
}

export default Navbar;

const StyledContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titleWrapper{
        .title{
            font-family: vazir;
            font-weight: 700;
            font-size: 20px;
            color: #fff
        }
    }
`
const StyledNavbar = styled.div`
    top: 0;
    left: 0;
    height: 60px;
    width: calc(100vw - 200px);
    position: fixed;
    z-index: 999;
    background-color: #333;
    display: flex;
    align-items: center;
`