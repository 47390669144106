import React, { useEffect, useState } from 'react';
import { listTraders } from "../../api/traders";

import styled from 'styled-components';
import { CustomButton } from '../../components/customButton'
import { FormControl, InputGroup, Table, Spinner, Card, Container, Form, Button, Modal, Dropdown} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch, faTimes, faPlusCircle} from '@fortawesome/free-solid-svg-icons'

import { logIn, createUser, updateTrader } from '../../api/traders';

import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";






const Traders = (props) => {
    const [traders, setTraders] = useState([]);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [selected, setSelected] = useState("2");
    const [modalSteps, setModalSteps] = useState(1)
    const [modalState, setModalState] = useState([{show:false}, {show:false, id:""}]);
    const [tradersEmail, setTradersEmail] = useState('');
    const [tradersName, setTradersName] = useState('');
    const [tradersPassword, setTradersPassword] = useState('');
    const [tradersDriver, setTradersDriver] = useState('');
    const [tradersAPIkey, setTradersAPIkey] = useState('');
    const [tradersApiSecret, setTradersApiSecret] = useState('');
    const [tradersPassphrase, setTradersPassphrase] = useState('');
    const [tradersPablaAPIkey, setTradersPablaAPIkey] = useState('');



    const handleClose = () => {
        setModalState([{show:false}, {show:false, id:""}]);
        setModalSteps(1);
    };
    const handleShow = () => {

        setModalState([{show:true}, {show:false, id:""}]);
    }

    let navigate = useNavigate();

    const handleSubmitValidation = () => {
        let bool = false
        if (email !== '' && password !== '') bool = true
        return bool
    }

    const handleTraderSubmitValidation = () => {
        let bool = false
        if (tradersEmail !== '' && tradersPassword !== '' && tradersName !== '') bool = true
        return bool
    }

    const handleSelect = (value) => {
        setSelected(value);
    }

    const handleLogin = async () => {
        setTraders([])
        listTraders().then((res) => {
            setTraders(res.data.results)
        }).catch(e => console.log(e))

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!handleSubmitValidation()) {
            toast.error('تمام فیلد ها اجباری هستند')
            return
        }
        const data = {
            email: email,
            password: password,
        }
        await logIn(data)
            .then((res) => {
                if (res.status === 200) {
                    Cookies.set('CC-Token-access', res.data.tokens.access.token)
                    Cookies.set('CC-Token-refresh', res.data.tokens.refresh.token)
                    setLoggedIn(true)
                    handleLogin()
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    Cookies.remove("token");
                } else if (error.status === 403) toast.error('نام کاربری یا رمز عبور اشتباه است')
                else if (error.status === 400 && error.data.error === 'wrong_credentials') toast.error('نام کاربری یا رمز عبور اشتباه است')
                else toast.error('مشکلی پیش آمده است. دوباره تلاش کنید')
                console.log('error', error)
            })
    };



    const handleTraderSubmit = async (event) => {
        event.preventDefault();
        if (!handleTraderSubmitValidation()) {
            toast.error('تمام فیلد ها اجباری هستند')
            return
        }
        const data = {
            email: tradersEmail,
            password: tradersPassword,
            name: tradersName

        }
        await createUser(data)
            .then((res) => {
                if (res.status === 201) {
                    setModalState([{show:false}, {show:true, id:res.data.trader.id}])
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    toast.error('تمام فیلد ها را دوباره بررسی کنید.')
                } else if (error.status === 403) toast.error('نام کاربری یا رمز عبور اشتباه است')
                else if (error.status === 400 && error.data.error === 'wrong_credentials') toast.error('نام کاربری یا رمز عبور اشتباه است')
                else toast.error('مشکلی پیش آمده است. دوباره تلاش کنید')
                console.log('error', error)
            })
    };

    const handleUpdateTrader = async (event) => {
        event.preventDefault();
        if (!handleTraderSubmitValidation()) {
            toast.error('تمام فیلد ها اجباری هستند')
            return
        }
        const data = {
            id:modalState[1].id,
            driver: tradersDriver,
            apikey: tradersAPIkey,
            apiSecret: tradersApiSecret,
            apiPassphrase: tradersPassphrase,
            pabla_apikey: tradersPablaAPIkey

        }
        await updateTrader(data)
            .then((res) => {
                if (res.status === 200) {
                    setModalState([{show:false}, {show:false, id:""}])
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    toast.error('تمام فیلد ها را دوباره بررسی کنید.')
                } else if (error.status === 403) toast.error('تمام فیلد ها را دوباره بررسی کنید.')
                else if (error.status === 400 && error.data.error === 'wrong_credentials') toast.error('تمام فیلد ها را دوباره بررسی کنید.')
                else toast.error('مشکلی پیش آمده است. دوباره تلاش کنید')
                console.log('error', error)
            })
    };

    useEffect(() => {

        if (Cookies.get('CC-Token-refresh')) setLoggedIn(true)

        handleLogin()
    }, [])

    if (!loggedIn) {
        return (

            <Container className="text-center d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                <StyledCard>
                    <Card.Body>
                        <Title className="">ورود به کپی کت</Title>
                        <StyledForm className='mt-5' onSubmit={(e) => handleSubmit(e)} >
                            <Form.Group className="mb-3" >
                                <Form.Control type="email" value={email} placeholder=" ایمیل " dir="rtl" onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type="password" value={password} placeholder=" رمز عبور " dir="rtl" onChange={(e) => setPassword(e.target.value)} />
                            </Form.Group>
                            <div className='d-flex justify-content-center'>
                                <CustomButton
                                    type="submit"
                                    text='ورود'
                                />
                            </div>
                        </StyledForm>
                    </Card.Body>
                </StyledCard>
            </Container>

        )
    }
    else {
        return (
            <div>
                <button
                    onClick={handleShow}
                    style={{
                        border: '1px solid',
                        margin: 5,
                        borderRadius: 5,
                        padding: 10,
                        backgroundColor: '#f2eea1'
                    }}> افزودن معامله گر <FontAwesomeIcon icon={faPlusCircle} /></button>

                <Modal show={modalState[0].show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>افزودن معامله گر</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                        <Form.Group className="mb-2" controlId="formBasisName">
                            <Form.Label>نام و نام خانوادگی</Form.Label>
                            <Form.Control
                            type="text"
                            value={tradersName}
                            placeholder="Albert Einstein"
                            onChange={(e) => setTradersName(e.target.value)}
                            autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasisEmail">
                            <Form.Label>ایمیل</Form.Label>
                            <Form.Control
                            type="email"
                            value={tradersEmail}
                            placeholder="name@example.com"
                            onChange={(e) => setTradersEmail(e.target.value)}
                            autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicPassword">
                            <Form.Label>پسورد</Form.Label>
                            <Form.Control
                            type="password"
                            value={tradersPassword}
                            onChange={(e) => setTradersPassword(e.target.value)}
                            placeholder="پسورد ایمنی انتخاب کنید"
                            autoFocus
                            />
                        </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                        بستن
                        </Button>
                        <Button variant="primary" onClick={handleTraderSubmit}>
                        افزودن
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                <Modal show={modalState[1].show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>اطلاعات حساب صرافی معامله گر</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                    صرافی :{tradersDriver}  
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/kucoin" onClick={() => setTradersDriver('kucoin')} >kucoin</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form.Group className="mb-2" controlId="formBasisEmail">
                            <Form.Label>API key</Form.Label>
                            <Form.Control
                            type="text"
                            value={tradersAPIkey}
                            onChange={(e) => setTradersAPIkey(e.target.value)}
                            autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicPassword">
                            <Form.Label>API Secret</Form.Label>
                            <Form.Control
                            type="text"
                            value={tradersApiSecret}
                            onChange={(e) => setTradersApiSecret(e.target.value)}
                            autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicPassword">
                            <Form.Label>API Passphrase</Form.Label>
                            <Form.Control
                            type="text"
                            value={tradersPassphrase}
                            onChange={(e) => setTradersPassphrase(e.target.value)}
                            autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="formBasicPassword">
                            <Form.Label>Pabla API key</Form.Label>
                            <Form.Control
                            type="text"
                            value={tradersPablaAPIkey}
                            onChange={(e) => setTradersPablaAPIkey(e.target.value)}
                            autoFocus
                            />
                        </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                        بستن
                        </Button>
                        <Button variant="primary" onClick={handleUpdateTrader}>
                        ثبت
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ActionsHeader>
                    <Form.Select
                        aria-label="Default select example"
                        className="mb-3"
                        style={{ width: '30%' }}
                        value={selected}
                        onChange={(e) => handleSelect(e.target.value)}
                    >
                        <option
                            as="select"
                            value="2"
                        >وضعیت معامله گر (همه معامله گران)</option>
                        <option value="0">فعال</option>
                        <option value="1">غیر فعال</option>
                    </Form.Select>

                    <InputGroup
                        className="mb-3"
                        style={{
                            width: '30%',
                            padding: '10px 15px',
                            fontSize: 17,
                        }}
                    >
                        <FormControl
                            placeholder="جست‌و‌جو"
                            aria-label="[[Username]]"
                            aria-describedby="basic-addon1"
                        />
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>

                    </InputGroup>
                </ActionsHeader>

                <Table striped hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>نام</th>
                        <th>نام خانوادگی</th>
                        <th>شماره موبایل</th>
                        <th>وضعیت</th>
                        <th>صرافی</th>
                        <th>تاریخ ثبت</th>
                    </tr>
                </thead>
                <tbody>
                {traders && traders.map((u, i) => {
                            
                            return (
                                <tr 
                                    onClick={() => navigate(`/console/traders/${u.id}`)}
                                >   
                                    <td>{i + 1}</td>
                                    <td>{u.user.name.split('_')[0]}</td>
                                    <td>{u.user.name.split('_')[1]}</td>
                                    <td>{u.phone}</td>
                                    <td>{u.primary_synced ? <FontAwesomeIcon icon={faCheck} style={{ color: "#07f184" }} /> : <FontAwesomeIcon icon={faTimes} style={{ color: "#fa3706" }} />}</td>
                                    <td>{u.driver}</td>
                                    <td>{new Date(u.createdAt).toLocaleDateString('fa-IR')}</td>
                                </tr>
                            )
                        })}

                </tbody>
            </Table>



            </div>
        )

    }


}

export default Traders;


const StyledCard = styled(Card)`
    width: 35rem;
    box-shadow: 0px 2px 8px #cecece;
    border: none;
    font-family: vazir
`
const StyledForm = styled(Form)`
    padding: 0 20px
`
const StyledButton = styled(Button)`
   width: 10rem;
   font-size: 20px;
   font-weight: bold;
`
const Title = styled.span`
   font-size: 25px;
   font-weight: bold;
`
const ActionsHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    text-align: center;
`