import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { retrievePortfolio } from "../../api/users";
import { Table, Spinner } from 'react-bootstrap';
import { Button } from "react-bootstrap";

const PortfolioDetails = (props) => {
    let params = useParams();
    let navigate = useNavigate();
    const [userPortfolioDetails, setUserPortfolioDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const groupByKey = (list, key) => {
        return list.reduce(
            (hash, obj) => ({
                ...hash,
                [obj['instrument'][key]]: (hash[obj['instrument'][key]] || []).concat(obj)
            }),
            {}
        );
    };



    const aggregateByKey = (list) => {
        let result = [];
        const grouped = groupByKey(list, 'symbol');

        for (let i in grouped) {
            const items = grouped[i];
            const aggregated = items.reduce((acc, cur) => {
                return {
                    unit: parseFloat(acc.unit) + parseFloat(cur.unit),
                    purchase: parseFloat(acc.purchase) + (parseFloat(cur.unit) * parseFloat(cur.initial_price)),
                    value: Number(parseFloat(acc.value) + (parseFloat(cur.unit) * parseFloat(cur.instrument.sell_price))).toFixed(0),
                    // pross : profit/loss
                }
            }, {
                unit: 0,
                purchase: 0,
                value: 0,
            })

            result.push({
                items: items,
                instrument: grouped[i][0].instrument,
                aggregated: aggregated,
            })
        }

        return result
    }

    useEffect(() => {
        setUserPortfolioDetails({})
        setIsLoading(true);
        retrievePortfolio(params.username, params.id).then((res) => {
            setUserPortfolioDetails(res.data)
            setIsLoading(false);
        })
    }, [params.username, params.id])

    return (
        <>
            <Button 
                onClick={() => navigate(-1)} 
                variant="warning" 
                size="lg">
                    {' '}
                    بازگشت
            </Button>
            <Table striped hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>حجم</th>
                        <th>مقدار خرید</th>
                        <th>ارزش فعلی</th>
                        <th>(%)سود/ضرر</th>
                        <th>وضعیت</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) :
                        userPortfolioDetails.assets && aggregateByKey(userPortfolioDetails.assets).map((parent) => {
                            const pross = Number(parseFloat(parent.aggregated.value) - parent.aggregated.purchase).toFixed(0);
                            const prossPercentage = Number((parseFloat(parent.aggregated.value) - parent.aggregated.purchase) / parent.aggregated.purchase * 100).toFixed(2);
                            return (
                                <>
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>{parent.instrument.name}</td>
                                        <td style={{ fontWeight: "bold" }}>{Number(parent.aggregated.unit).toFixed(6)}</td>
                                        <td style={{ fontWeight: "bold" }}>{Number(parent.aggregated.purchase).toFixed(0)} تومان </td>
                                        <td style={{ fontWeight: "bold" }}>{parseFloat(parent.aggregated.value)} تومان</td>
                                        <td style={pross >= 0 ? { color: "green", fontWeight: 'bold' } : { color: "red", fontWeight: 'bold' }} > {pross} تومان <span dir="ltr">({prossPercentage}%)</span></td>
                                        <td></td>
                                    </tr>
                                    <>
                                        {
                                            parent.items.map(asset => {
                                                const purchase = Number(asset.unit * asset.initial_price).toFixed(0);
                                                const value = Number(asset.unit * asset.instrument.sell_price).toFixed(0);
                                                const prossPercentage = Number((value - purchase) * 100 / purchase).toFixed(2);
                                                const pross = Number(value - purchase).toFixed(2);
                                                // pross : proffit/loss
                                                return (
                                                    <tr>
                                                        <td></td>
                                                        <td>{Number(asset.unit).toFixed(6)}</td>
                                                        <td>{purchase} تومان </td>
                                                        <td>{value} تومان</td>
                                                        <td style={pross >= 0 ? { color: "green" } : { color: "red" }} > {pross} تومان <span dir="ltr">({prossPercentage}%)</span></td>
                                                        <td>{asset.is_closed ? 'بسته شده' :
                                                            "باز"}</td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </>

                                </>)
                        })
                        // (userPortfolioDetails.assets && userPortfolioDetails.assets.map((asset) => {

                        // }))
                    }

                </tbody>
            </Table>
        </>
    )
}

export default PortfolioDetails;