import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";


import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

const DashboardLayout = (props) => {
  const { pageTitle } = props
  return (
    <>
      <Sidebar />
      <Navbar pageTitle={pageTitle}/>
      <StyledMain>
        <Container>{props.children}</Container>
      </StyledMain>
    </>

  )
}

export default DashboardLayout;

const StyledMain = styled.main`
  width: calc(100vw - 200px);
  overflow-x: scroll;
  padding-top: 15px;
  height: calc(100vh - 60px);
  top: 60px;
  position: absolute;
  left: 0;
`