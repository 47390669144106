import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { retrieveTrader, getTraderAssets, subscription, initialization, synchronization } from '../../api/traders';
import { Table, Spinner, Modal, Form, Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { CustomButton } from '../../components/customButton';
import { toast } from 'react-toastify';
import { updateTrader } from '../../api/traders';




const UserDetails = (props) => {
    let params = useParams();


    const [traderDetails, setTraderDetails] = useState({});
    const [traderAssets, setTraderAssets] = useState({
        assets: [],
        InitialBalance: 0
    });
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);




    useEffect(() => {
        setIsLoading(true);
        getTraderAssets(params.id)
            .then((res) => {
                if (res.status === 200) {
                    setTraderAssets(res.data)
                }
                setIsLoading(false);

            }).catch((error) => {
                console.log(error);
                setIsLoading(false);

            })
        setTraderDetails({})
        retrieveTrader(params.id).then((res) => {
            setTraderDetails(res.data)
        })
    }, [params.id])

    return (
        <>
            <HeaderActions traderDetails={traderDetails} />

            <div className='d-flex flex-row mt-5'>
                <h4>ارزش ریالی پورتفولیو : </h4>
                <h4 className='me-5 mt-1'>{parseInt(traderAssets.InitialBalance)}</h4>
                <h4 className='me-2 mt-1'>تومان</h4>
            </div>

            <br />
            <h2 style={{ fontWeight: 'bold' }}>دارایی ها</h2>
            <Table striped hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>رمزارز</th>
                        <th>میزان سرمایه گذاری</th>
                    </tr>
                </thead>
                <tbody>

                    {isLoading ? (
                        <Spinner animation="border" role="status" />
                    )
                        : (traderAssets.assets.length > 0 && traderAssets.assets.map((u, i) => {
                            return (
                                <tr key={u.id}
                                >
                                    <td>{i + 1}</td>
                                    <td>{u.currency}</td>
                                    <td>{u.balance}</td>
                                </tr>
                            )
                        }))}

                </tbody>
            </Table>
        </>
    )
}

export default UserDetails;

const HeaderActions = (props) => {

    let params = useParams();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { traderDetails } = props

    const handleInit = (value) => {
        initialization(params.id).then((res) => {
            console.log('a');
        })
    }

    const handleSync = (value) => {
        synchronization(params.id).then((res) => {
            console.log('b');
        });
    }

    const handleConnectWs = (value) => {
        subscription(params.id).then((res) => {
            console.log('c');
        });
    }

    const handleModalShow = () => {

    }

    return (
        <div>
            <CustomButton text={traderDetails?.primary_synced ? "متصل شده" : "اتصال اولیه"} disabled={traderDetails?.primary_synced}
                onClick={() => handleInit(traderDetails?.id)} style={{
                    marginLeft: 6
                }} />
            <CustomButton text="هماهنگ سازی" onClick={() => handleSync(traderDetails?.id)} style={{
                marginLeft: 6
            }} />
            <CustomButton text="اتصال Web Socket" onClick={() => handleConnectWs(traderDetails?.id)} style={{
                marginLeft: 6
            }} />
            <CustomButton text="ویرایش معامله گر" onClick={() => handleShow()} />
            <UpdateTraderModal show={show} onHide={handleClose} />

        </div>
    )

}


const UpdateTraderModal = (props) => {

    const [tradersDriver, setTradersDriver] = useState('kucoin');
    const [tradersAPIkey, setTradersAPIkey] = useState('');
    const [tradersApiSecret, setTradersApiSecret] = useState('');
    const [tradersPassphrase, setTradersPassphrase] = useState('');
    const [tradersPablaAPIkey, setTradersPablaAPIkey] = useState('');


    const handleUpdateTrader = async (event) => {
        event.preventDefault();

        const data = {
            // id:modalState[1].id,
            driver: tradersDriver,
            apikey: tradersAPIkey,
            apiSecret: tradersApiSecret,
            apiPassphrase: tradersPassphrase,
            pabla_apikey: tradersPablaAPIkey

        }
        await updateTrader(data)
            .then((res) => {
                if (res.status === 200) {
                    props.onHide()
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    toast.error('تمام فیلد ها را دوباره بررسی کنید.')
                } else if (error.status === 403) toast.error('تمام فیلد ها را دوباره بررسی کنید.')
                else if (error.status === 400 && error.data.error === 'wrong_credentials') toast.error('تمام فیلد ها را دوباره بررسی کنید.')
                else toast.error('مشکلی پیش آمده است. دوباره تلاش کنید')
                console.log('error', error)
            })
    };


    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header>
                <Modal.Title>اطلاعات حساب صرافی معامله گر</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                صرافی :{tradersDriver}  
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/kucoin" onClick={() => setTradersDriver('kucoin')} >kucoin</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Form.Group className="mb-2" controlId="formBasisEmail">
                        <Form.Label>API key</Form.Label>
                        <Form.Control
                            type="text"
                            value={tradersAPIkey}
                            onChange={(e) => setTradersAPIkey(e.target.value)}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formBasicPassword">
                        <Form.Label>API Secret</Form.Label>
                        <Form.Control
                            type="text"
                            value={tradersApiSecret}
                            onChange={(e) => setTradersApiSecret(e.target.value)}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formBasicPassword">
                        <Form.Label>API Passphrase</Form.Label>
                        <Form.Control
                            type="text"
                            value={tradersPassphrase}
                            onChange={(e) => setTradersPassphrase(e.target.value)}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formBasicPassword">
                        <Form.Label>Pabla API key</Form.Label>
                        <Form.Control
                            type="text"
                            value={tradersPablaAPIkey}
                            onChange={(e) => setTradersPablaAPIkey(e.target.value)}
                            autoFocus
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    بستن
                </Button>
                <Button variant="primary" onClick={handleUpdateTrader}>
                    ثبت
                </Button>
            </Modal.Footer>
        </Modal>
    )
}