import React, { useEffect, useState } from 'react'
import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const PrivateRoutes = ({ children }) => {
    const token = Cookies.get('token')
    // for make private nested routing use Outlet from react-router-dom and read documentation

    if(token) return children
    else {
        return <Navigate to='/login' />
    }
};

export default PrivateRoutes