import axios from 'axios'
import { formatURL } from '../utils'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie';


export const fetchAPI = (url, method, data, hasFile) => new Promise((resolve, reject) => {


    let config = {
        method: method || 'GET',
        url: formatURL(url),
        headers: {
            'Content-Type': hasFile ? 'multipart/form-data' : 'application/json',
        }
    }
    const token = Cookies.get('token');
    if (token) {
        config.headers.Authorization = 'Token ' + token;
    }
    

    
    if (hasFile) {
        const formData = new FormData()

        for (let key in data) {
            formData.append(key, data[key])
        }
        config.data = formData
    } else if (data) {
        config.data = data
    }
    axios(config).then(res => {
        resolve({
            status: res.status,
            statusText: res.statusText,
            data: res.data
        })
    }).catch(error => {
        const { response } = error
        if (response.status===401) {
            Cookies.remove("token");
        }
        if (response) {
            reject({
                status: response.status,
                statusText: response.statusText,
                data: response.data
            })
        } else {
            reject(error)
        }
    })
})