import {createStore, compose, applyMiddleware} from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import thunk from 'redux-thunk'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { reducers } from './reducers'

const persistConfig = {
  key: 'root',
  storage: storage,
  timeout: 0,
  stateReconciler: autoMergeLevel2,
  blacklist: []
}

const persistedReducers = persistReducer(persistConfig, reducers)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(persistedReducers, /* preloadedState, */ composeEnhancers(
  applyMiddleware(thunk),
));

export const persistor = persistStore(store)
