 import { fetchAPI } from './';


 export const getDepositsStats = async () => {
     return await fetchAPI(`admin/deposits/stats/`, 'GET')
 }

 export const getDepositsChart = async (periodDeposit) => {
    return await fetchAPI(`admin/deposits/chart/?group_by=${periodDeposit}`, 'GET')
}

export const getWithdrawalsStats = async () => {
    return await fetchAPI(`admin/withdrawals/stats/`, 'GET')
}

export const getWithdrawalsChart = async (periodWithdrawals) => {
    return await fetchAPI(`admin/withdrawals/chart/?group_by=${periodWithdrawals}`, 'GET')
}