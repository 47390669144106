import React, { useState, useEffect } from "react";
import {
  getDepositsStats,
  getDepositsChart,
  getWithdrawalsStats,
  getWithdrawalsChart,
} from "../../api/accounting";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import styled from "styled-components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Accounting = (props) => {
  const [depositsStats, setDepositsStats] = useState({});
  const [withdrawalsStats, setWithdrawalsStats] = useState({});
  const [depositsChart, setDepositsChart] = useState([]);
  const [withdrawalsChart, setWithdrawalsChart] = useState([]);
  const [periodDeposit, setPeriodDeposit] = useState("month");
  const [periodWithdrawals, setPeriodWithdrawals] = useState("month");

  useEffect(() => {
    getDepositsStats().then((res) => {
      setDepositsStats(res.data);
    //   setWithdrawalsStats(res.data);
    });
    getDepositsChart(periodDeposit).then((res) => {
      setDepositsChart(res.data.results);
      //setPeriodDeposit(res.data);
    });

    getWithdrawalsChart(periodWithdrawals).then((res) => {
      setWithdrawalsChart(res.data.results);
    });

    getWithdrawalsStats().then((res) => {
        setWithdrawalsStats(res.data);
    });
  }, [periodDeposit, periodWithdrawals]);


  const depositsLabel = depositsChart.map((d) =>
    new Date(d.created_at).toLocaleDateString("fa")
  );

  const depositsData = {
    labels: depositsLabel,
    datasets: [
      {
        label: "نمودار برداشت",
        data: depositsChart.map((d) => d.amount),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const withdrawalsLabel = withdrawalsChart.map((d) =>
    new Date(d.created_at).toLocaleDateString("fa")
  );
  
  const withdrawalsData = {
    labels: withdrawalsLabel,
    datasets: [
      {
        label: "نمودار واریز",
        data: withdrawalsChart.map((d) => d.amount),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div className="container">
      <div className="row align-items-start">
        <h5>برداشت ها :</h5>
        <div className="col">مجموع برداشت</div>
        <div className="col">24 ساعت گذشته</div>
        <div className="col">یک هفته گذشته</div>
      </div>

      <div className="row align-items-center">
        <div className="col">{depositsStats.total}</div>
        <div className="col">
          {depositsStats.day} ({parseFloat(depositsStats.day_change).toFixed(1)}
          %)
        </div>
        <div className="col">
          {depositsStats.week} (
          {parseFloat(depositsStats.week_change).toFixed(1)}%)
        </div>

        <div className='mt-5'>
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => setPeriodDeposit("day")}
          >
            روزانه
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => setPeriodDeposit("week")}
          >
            هفتگی
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => setPeriodDeposit("month")}
          >
            ماهانه
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => setPeriodDeposit("year")}
          >
            سالانه
          </button>
        </div>
      </div>
      <Line data={depositsData} />

      <div className="row align-items-start">
        <h5 className="mt-5">واریزها :</h5>
        <div className="col">مجموع برداشت</div>
        <div className="col">24 ساعت گذشته</div>
        <div className="col">یک هفته گذشته</div>
        <div className="col">برداشت به ازای هر کاربر</div>
      </div>

      <div className="row align-items-center">
        <div className="col">{withdrawalsStats.total}</div>
        <div className="col">
          {withdrawalsStats.day} (
          {parseFloat(withdrawalsStats.day_change).toFixed(1)}
          %)
        </div>
        <div className="col">
          {withdrawalsStats.week} (
          {parseFloat(withdrawalsStats.week_change).toFixed(1)}%)
        </div>
        <div className="col"></div>
      </div>

      <div className="mt-5">
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => setPeriodWithdrawals("day")}
        >
          روزانه
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => setPeriodWithdrawals("week")}
        >
          هفتگی
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => setPeriodWithdrawals("month")}
        >
          ماهانه
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => setPeriodWithdrawals("year")}
        >
          سالانه
        </button>
      </div>

      <Line data={withdrawalsData} />

      <div className="row align-items-start">
        <h5 className="mt-5">spread ها :</h5>
        <div className="col">مجموع برداشت</div>
        <div className="col">24 ساعت گذشته</div>
        <div className="col">یک هفته گذشته</div>
      </div>

      <div className="row align-items-center">
        <div className="col">{depositsStats.total}</div>
        <div className="col">
          {depositsStats.day} ({parseFloat(depositsStats.day_change).toFixed(1)}
          %)
        </div>
        <div className="col">
          {depositsStats.week} (
          {parseFloat(depositsStats.week_change).toFixed(1)}%)
        </div>
      </div>
    </div>
  );
};

export default Accounting;

