import React, { useState, useEffect} from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { login } from '../api/auth';
import { CustomButton } from '../components/customButton'
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux'
import { loginUser, logoutUser } from '../actions'
import { toast } from 'react-toastify';


function Login() {
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactor, setTwoFactor] = useState(''); 
  const [loading, updateLoading] = useState(false); 


  const handleSubmitValidation = () => {
    let bool = false
    if(username !== '' && password !== '' && twoFactor !== '')  bool = true
    return bool
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!handleSubmitValidation()) {
      toast.error('تمام فیلد ها اجباری هستند')
      return
    } 
    const data = {
      username: username,
      password: password,
      code: twoFactor
    }
    updateLoading(true)
    await login(data)
    .then((res) => {
      if(res.status === 200) {
          dispatch(loginUser(res.data.token, res.data.user))
          navigate('/console/home', { replace: true });
      }
      updateLoading(false)
    })
    .catch((error) => {
      if (error.status === 401) {
        Cookies.remove("token");
        dispatch(logoutUser())
      }else if(error.status === 403) toast.error('کد وارد شده اشتباه است')
      else if(error.status === 400 && error.data.error === 'wrong_credentials') toast.error('نام کاربری یا رمز عبور اشتباه است')
      else toast.error('مشکلی پیش آمده است. دوباره تلاش کنید')
      updateLoading(false)
      console.log('error',  error)
    })
  };

  useEffect(() => {
    dispatch(logoutUser())
  }, [])
  
  return (
    <Container className="text-center d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
      <StyledCard>
        <Card.Body>
          <Title className="">ورود به پنل</Title>
            <StyledForm className='mt-5' onSubmit={(e) => handleSubmit(e)}>
              <Form.Group className="mb-3" >
                <Form.Control type="text" value={username} placeholder=" نام کاربری " dir="rtl" onChange={(e) => setUsername(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="password" value={password} placeholder=" رمز عبور " dir="rtl" onChange={(e) => setPassword(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Control type="text" placeholder=" کد دو عاملی " dir="rtl" onChange={(e) => setTwoFactor(e.target.value)} />
              </Form.Group>
              <div className='d-flex justify-content-center'>
                <CustomButton 
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  text='ورود'
                />
              </div>
            </StyledForm>
        </Card.Body>
      </StyledCard>
    </Container>
  );
}

export default Login;

const StyledCard = styled(Card)`
    width: 35rem;
    box-shadow: 0px 2px 8px #cecece;
    border: none;
    font-family: vazir
`
const StyledForm = styled(Form)`
    padding: 0 20px
`
const StyledButton = styled(Button)`
   width: 10rem;
   font-size: 20px;
   font-weight: bold;
`
const Title = styled.span`
   font-size: 25px;
   font-weight: bold;
`


