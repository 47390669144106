import React from 'react';
import Login from '../screens/Login';
import DashboardLayout from '../components/layouts/DashboardLayout';
import Inventories from '../screens/dashboard/Inventories';
import Withdrawals from '../screens/dashboard/Withdrawals';
import Accounting from '../screens/dashboard/Accounting';
import Home from '../screens/dashboard/Home';
import Users from '../screens/dashboard/Users';
import UserDetails from '../screens/dashboard/UserDetails';
import TraderDetails from '../screens/dashboard/TraderDetails';
import Traders from '../screens/dashboard/Traders';
import {
    Route,
    Routes,
} from "react-router-dom";
import PortfolioDetails from '../screens/dashboard/PortfolioDetails';
import PrivateRoute from './PrivateRoutes'

const MyRoutes = () => {
    return (
          <Routes>
            <Route 
                path='/' 
                element={<Login />} 
            />
            <Route 
                path='/login' 
                element={<Login />} 
            />
            <Route 
                path='/console/home'
                element={
                    <PrivateRoute>
                        <DashboardLayout pageTitle='خانه'>
                            <Home />
                        </DashboardLayout>
                    </PrivateRoute>
                } 
           />
            <Route 
                path='/console/users'
                element={
                    <PrivateRoute>
                        <DashboardLayout pageTitle='کاربران'>
                            <Users />
                        </DashboardLayout>
                    </PrivateRoute>
                } 
            />
            <Route 
                path='/console/users/:username'
                element={
                    <PrivateRoute>
                        <DashboardLayout pageTitle='کاربر'>
                            <UserDetails/>
                        </DashboardLayout>
                    </PrivateRoute>
                } 
            />
            <Route 
                path='/console/users/:username/portfolios/:id'
                element={
                    <PrivateRoute>
                        <DashboardLayout pageTitle='پورتفولیو'>
                            <PortfolioDetails/>
                        </DashboardLayout>
                    </PrivateRoute>
                } 
            />
            <Route 
                path='/console/inventories'
                element={
                    <PrivateRoute>
                        <DashboardLayout pageTitle='انبار'>
                            <Inventories />
                        </DashboardLayout>
                    </PrivateRoute>
                } 
            />
            <Route 
                path='/console/withdrawals'
                element={
                    <PrivateRoute>
                        <DashboardLayout pageTitle='برداشت ها'>
                            <Withdrawals />
                        </DashboardLayout>
                    </PrivateRoute>
                } 
            />
            <Route 
                path='/console/accounting'
                element={
                    <PrivateRoute>
                        <DashboardLayout pageTitle='حساب داری'>
                            <Accounting />
                        </DashboardLayout>
                    </PrivateRoute>
                } 
            />
            <Route 
                path='/console/traders'
                element={
                    <PrivateRoute>
                        <DashboardLayout pageTitle='معامله گران'>
                            <Traders />
                        </DashboardLayout>
                    </PrivateRoute>
                } 
            />
            <Route 
                path='/console/traders/:id'
                element={
                    <PrivateRoute>
                        <DashboardLayout pageTitle='معامله گر'>
                            <TraderDetails />
                        </DashboardLayout>
                    </PrivateRoute>
                } 
            />


        </Routes>
    )
}

export default MyRoutes;