import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { InputGroup, Table, FormControl, Modal, Form, Button, Spinner, Container, Row, Col, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";

import {
  listWithdrawal,
  acceptWithdrawal,
  rejectWithdrawal,
  initWithdrawal,
} from "../../api/payments";
import { PAGE_SIZE } from "../../constants/api";
import { useDebouncedCallback } from "use-debounce";
import { CustomButton } from '../../components/customButton'
import { colors } from '../../assets/Colors'
import { transactionsStatus, withdrawalOrderingTypes } from '../../constants'


const Withdrawals = (props) => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [selectedWithdraw, setSelectedWithdraw] = useState({});
  const [page, setPage] = useState(1);
  const [withdrawalCount, setWithdrawalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [listLoading, updateListLoading] = useState(false)
  const [withdrawalLoading, setInitWithdrawalLoading] = useState(false)
  const [listOrdering, updateListOrdering] = useState({
    key: 0,
    slug: 'created_at',
    title: 'تاریخ'
  })

  const debounced = useDebouncedCallback((text) => {
    setSearchQuery(text);
  }, 400);
 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getListOfWithdrawalsApiCall = () => {
    updateListLoading(true)
    listWithdrawal(searchQuery, page, listOrdering.slug)
    .then((res) => {
      setWithdrawals(res.data.results);
      setWithdrawalCount(res.data.count);
      updateListLoading(false)
    })
    .catch(e => {
      updateListLoading(false)
      console.log('error in getting list of withdrawals', e);
    })
  }

  useEffect(() => {
    getListOfWithdrawalsApiCall()
  }, [searchQuery, page, listOrdering.key]);

  const acceptWithdrawalHandler = (id) => {
    const data = {
      withdrawals: [{id: id, transaction: transaction}],
    };
    setConfirmationLoading(id);
    acceptWithdrawal(data).then((res) => {
      if (res.status === 200) {
        let newWithdrawal = withdrawals.map((w) => {
          if (w.id === id) {
            w.state = 1;
          }
          return w;
        });
        setWithdrawals(newWithdrawal);
        handleClose();
      }
      setConfirmationLoading(false);
    });
  };

  const rejectWithdrawalHandler = (id) => {
    const data = {
      withdrawals: [id],
    };
    setConfirmationLoading(id)
    rejectWithdrawal(data).then((res) => {
      if (res.status === 200) {
        let newWithdrawal = withdrawals.map((w) => {
          if (w.id === id) {
            w.state = 2;
          }
          return w;
        });
        setWithdrawals(newWithdrawal);
      }
      setConfirmationLoading(false);
    })
  }

  const handleUpdateWithdrawRequest = async withdraw => {
    const data = {
      withdrawals: [withdraw.id],
    }
    setInitWithdrawalLoading(withdraw.id)
    try{
      let res = await initWithdrawal(data)
      if(res.status === 200) {
        let newWithdrawal = withdrawals.map((w) => {
          if (w.id === withdraw.id) {
            w.state = 4;
          }
          return w;
        })
        setWithdrawals(newWithdrawal);
      }
    }catch(error){
      console.error(error)
    }
    setInitWithdrawalLoading(false)
  }

  const getStateColor = (state) => {
    switch (state) {
      case 0:
        return "orange";
      case 1:
        return "#07f184";
      case 2:
        return "#fa3706";
      case 3:
        return "black";
      case 4:
        return "orange";
      default:
        return "black";
    }
  };

  const stateChecker = (state) => {
    let currentState = ''
    Object.keys(transactionsStatus).forEach(s => {
        if (parseInt(s) === parseInt(state)) {
            currentState = transactionsStatus[s]
        }
    })
    return currentState
  }

  const handleSelectWithdraw = (withdraw) => {
    handleShow()
    setSelectedWithdraw(withdraw)
  }


  const handleRenderCallToActions = withdraw => {
    switch (withdraw.state){
      case 0:
        return (
          <div className="input-group-append">
            <CustomButton
              onClick={() => handleUpdateWithdrawRequest(withdraw)}
              buttonColor={colors.primaryColor}
              text='تایید و ادامه'
              style={{margin: '3px'}}
              textStyle={{fontSize: '13px', fontWeight: 'bold'}}
              width={'100%'}
              loading={withdrawalLoading === withdraw.id}
              disabled={withdrawalLoading === withdraw.id}
            />
            <CustomButton
              onClick={() => rejectWithdrawalHandler(withdraw.id)}
              buttonColor={colors.red}
              text='لغو برداشت'
              disabled={confirmationLoading === withdraw.id}
              loading={confirmationLoading === withdraw.id}
              style={{margin: '3px'}}
              textStyle={{fontSize: '13px', fontWeight: 'bold'}}
              width={'100%'}
            />
          </div>
        )
      case 4:
        return (
          <div className="input-group-append">
            <CustomButton
              onClick={() => handleSelectWithdraw(withdraw)}
              buttonColor={colors.green}
              text='انجام برداشت'
              style={{margin: '3px'}}
              textStyle={{fontSize: '13px', fontWeight: 'bold'}}
              width={'100%'}
            />
            <CustomButton
              onClick={() => rejectWithdrawalHandler(withdraw.id)}
              buttonColor={colors.red}
              text='لغو برداشت'
              disabled={confirmationLoading === withdraw.id}
              loading={confirmationLoading === withdraw.id}
              style={{margin: '3px'}}
              textStyle={{fontSize: '13px', fontWeight: 'bold'}}
              width={'100%'}
            />
          </div>
        )
      default:
        return 
    }
  }

  const csvDataGenerator = () => {
    let data = withdrawals.map(withdraw => 
      [
        withdraw.bank_account?.sheba_number, 
        ((parseInt(withdraw.amount) - parseInt(withdraw.commission_fee)) * 10), 
        withdraw.transaction?.track_id, 
        withdraw.transaction?.transaction_id,
        `پرداخت به ${withdraw.user?.first_name + ' ' + withdraw.user?.last_name}`
        
      ])
    let header = ['Iban', 'Amount  (IR-Rial)', 'Payment number', 'Track id', 'Description']
    return {data, header}
  }

  return (
    <Wrapper>
      <HeaderContainer>
        <Row className="d-flex flex-row align-items-center">
          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <SearchBar>
              <InputGroup>
                <FormControl
                  placeholder="جستجو"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => debounced(e.target.value)}
                />
              </InputGroup>
            </SearchBar>
          </Col>
          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span>ترتیب بر اساس: <strong>{listOrdering.title}</strong></span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {withdrawalOrderingTypes.map(orderObject => 
                  <Dropdown.Item 
                    onClick={() => updateListOrdering(orderObject)}
                  >
                    <span>{orderObject.title}</span>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <CSVLink 
              data={csvDataGenerator().data} 
              headers={csvDataGenerator().header}
            >
                خروجی Excel
            </CSVLink>
          </Col>
        </Row>
      </HeaderContainer>
      
      <StyledTable striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>نام</th>
            <th>نام خانوادگی</th>
            <th>شماره موبایل</th>
            <th>شماره شبا</th>
            <th>مقدار برداشت</th>
            <th>وضعیت</th>
            <th>تاریخ درخواست</th>
            <th>تاریخ تسویه</th>
            <th>شماره تراکنش</th>
            <th>بررسی تاییدیه</th>
            <th></th>
          </tr>
        </thead>

       {listLoading 
        ? <Spinner animation="grow" className='mt-5' variant="dark" size="lg" />
        : <tbody>
            {withdrawals.map((w, i) => {
              return (
                <tr key={w.id}>
                  <td>{i + 1}</td>
                  <td>{w.user?.first_name}</td>
                  <td>{w.user?.last_name}</td>
                  <td>{w.user?.username}</td>
                  <td>{w.bank_account?.sheba_number}</td>
                  <td>{(parseInt(w.amount) - parseInt(w.commission_fee)).toLocaleString("fa")} تومان</td>
                  <td style={{ color: getStateColor(w.state) }}>
                    {stateChecker(w.state)}
                  </td>
                  <td>{new Date(w.created_at).toLocaleString("fa")}</td>
                  <td>{new Date(w.updated_at).toLocaleString("fa")}</td>
                  <td>{w.transaction?.track_id}</td>
                  <td style={{ color: getStateColor(w.sanity_check_state) }}>
                    {stateChecker(w?.sanity_check_state)}
                  </td>
                  <td>
                    <div className="input-group">
                      {handleRenderCallToActions(w)}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        }
      </StyledTable>
      <div>
        {Array(Math.ceil(withdrawalCount / PAGE_SIZE))
          .fill(null)
          .map((item, index) => (
            <button
              key={index}
              onClick={() => setPage(index + 1)}
              style={{
                backgroundColor: page === index + 1 ? "orange" : "white",
                border: "1px solid #999",
                margin: 5,
                borderRadius: 5,
                padding: 10,
              }}
            >
              {index + 1}
            </button>
          ))}
      </div>
      <AcceptWithdrawModal 
        show={show} 
        handleClose={handleClose} 
        withdrawDetail={selectedWithdraw}
        acceptWithdrawalHandler={acceptWithdrawalHandler} 
        confirmationLoading={confirmationLoading}
        setTransaction={setTransaction}
      />
    </Wrapper>
  )
}

export default Withdrawals;


const AcceptWithdrawModal = (props) => {
  const { 
    show, 
    handleClose, 
    withdrawDetail, 
    acceptWithdrawalHandler, 
    confirmationLoading,
    setTransaction
  } = props

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>تایید برداشت</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>برای انجام این برداشت کد تراکنش بانکی را وارد کنید</p>

        <h2>مشتری</h2>

        <h4>{withdrawDetail.user?.first_name} {withdrawDetail.user?.last_name}</h4>
        <h4>{(parseInt(withdrawDetail.amount) - parseInt(withdrawDetail.commission_fee)).toLocaleString("fa")} تومان</h4>
        
        <h4>شماره شبا :
            {withdrawDetail.bank_account?.sheba_number}
        </h4>
        
        <Form.Label htmlFor="basic-url">
          <h5>کد تراکنش :</h5>
        </Form.Label>
        <InputGroup className="mb-3">
          <FormControl
            id="basic-url"
            aria-describedby="basic-addon3"
            onChange={(e) => setTransaction(e.target.value)}
          />
        </InputGroup>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          بستن
        </Button>
        <Button variant="primary" disabled={confirmationLoading === withdrawDetail.id} onClick={() => acceptWithdrawalHandler(withdrawDetail.id)}>
          {confirmationLoading ? <Spinner animation="border" variant="warning"/> : 'انجام تایید برداشت'} 
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const Wrapper = styled.div`
  font-family: vazir;
`
const HeaderContainer = styled(Container)`
  margin-bottom: 20px;
`

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  text-align: center;
`;

const StyledTable = styled(Table)`
  font-family: vazir;
  font-weight: 300;
`
