import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { retrieveUser, portfolioUser } from '../../api/users';
import { Table, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const UserDetails = (props) => {
    let params = useParams();
    const [userDetails, setUserDetails] = useState({});
    const [userPortfolios, setUserPortfolios] = useState([]);
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setUserDetails({})
        setIsLoading(true);
        retrieveUser(params.username).then((res) => {
            setUserDetails(res.data)
            setIsLoading(false);
        })
    }, [params.username])

    useEffect(() => {
        setUserPortfolios([])
        setIsLoading(true);
        portfolioUser(params.username).then((res) => {
            setUserPortfolios(res.data.results)
            setIsLoading(false);
        })
    }, [params.username])

    return (
        <>
            <image src={userDetails.image_thumbnail} roundedCircle />
            <div className='d-flex flex-row align-items-center'>
                <div> {userDetails.first_name} {userDetails.last_name}</div>
                <div style={{ backgroundColor: 'purple' }}> {userDetails.is_trader ? <p3 style={{ color: '#fff', padding: '10px' }}>تریدر</p3> : <p4 style={{ color: '#fff', padding: '10px' }}> کاربر</p4>}</div>
            </div>
            <div> {userDetails.phone}</div>
            <p5> عضویت از {new Date(userDetails.created_at).toLocaleDateString('fa-IR')}</p5>
            <br />
            <br />
            <div style={{ fontWeight: 'bold' }}>موجودی </div>
            <div>{parseFloat(userDetails.balance?.amount)?.toLocaleString('fa')} تومان</div>
            <div>
                <button
                    style={{
                        border: '1px solid #999',
                        margin: 5,
                        borderRadius: 5,
                        padding: 10,
                    }}>درخواست برداشت</button>
                <button
                    style={{
                        border: '1px solid #999',
                        margin: 5,
                        borderRadius: 5,
                        padding: 10,
                    }}
                >واریز</button>
            </div>
            <br />
            <br />
            <p2 style={{ fontWeight: 'bold' }}>پورتفولیوها</p2>
            <Table striped hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>نام</th>
                        <th>سرمایه گذاری</th>
                        <th>ارزش فعلی</th>
                        <th>سودو ضرر</th>
                        <th>گزینه‌ها</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    )
                        : (userPortfolios && userPortfolios.map((u, i) => {
                            const pross = u.prev_value && Number((u.value - u.prev_value) * 100 / u.prev_value).toFixed(2);
                            return (
                                <tr 
                                    onClick={() => navigate(`/console/users/${u.username}/portfolios/${u.id}`)}
                                >
                                    <td>{i + 1}</td>
                                    <td>
                                        {
                                            u.source === null
                                                ? <>{u.name}</>
                                                : <>{u.source.user.first_name} {u.source.user.last_name}</>
                                        }
                                    </td>
                                    <td>{u.copy && u.copy.invested}</td>
                                    <td>{u.value}</td>
                                    <td style={{ color: pross > 0 ? "green" : "red" }}>{pross}%</td>
                                    <td></td>
                                </tr>
                            )
                        }))}

                </tbody>
            </Table>
        </>
    )
}

export default UserDetails;