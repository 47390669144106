import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { FormControl, InputGroup, Form, Table, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { listUsers } from "../../api/users";
import { PAGE_SIZE } from "../../constants/api";
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from "react-router-dom";

const Users = (props) => {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [usersCount, setUsersCount] = useState(0);
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState("2");

    const debounced = useDebouncedCallback(
        (value) => {
            setSearchQuery(value);
        },
        500
    );

    const handleSelect = (value) => {
        setSelected(value);
    };

    useEffect(() => {
        let options = {}

        if (selected !== "2") {
            options = {
                is_trader: selected === "0"
            }
        }

        setUsers([])
        setIsLoading(true);
        listUsers(searchQuery, page, options).then((res) => {
            setUsers(res.data.results)
            setUsersCount(res.data.count)
            setIsLoading(false);
        })
    }, [searchQuery, page, selected])


    return (
        <div>
            <ActionsHeader>
                <Form.Select
                    aria-label="Default select example"
                    className="mb-3"
                    style={{ width: '30%' }}
                    value={selected}
                    onChange={(e) => handleSelect(e.target.value)}
                >
                    <option
                        as="select"
                        value="2"
                    >نوع کاربر (همه کاربران)</option>
                    <option value="0">تریدر</option>
                    <option value="1">معمولی</option>
                </Form.Select>

                <InputGroup
                    className="mb-3"
                    style={{
                        width: '30%',
                        padding: '10px 15px',
                        fontSize: 17,
                    }}
                >
                    <FormControl
                        placeholder="جست‌و‌جو"
                        aria-label="[[Username]]"
                        aria-describedby="basic-addon1"
                        onChange={(e) => debounced(e.target.value)}
                    />
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>

                </InputGroup>
            </ActionsHeader>


            <Table striped hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>نام</th>
                        <th>نام خانوادگی</th>
                        <th>شماره موبایل</th>
                        <th>تریدر</th>
                        <th>فعال</th>
                        <th>تاریخ عضویت</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (

                        users && users.map((u, i) => {
                            return (
                                <tr 
                                    onClick={() => navigate(`/console/users/${u.username}`)}
                                >
                                    <td>{i + 1}</td>
                                    <td>{u.first_name}</td>
                                    <td>{u.last_name}</td>
                                    <td>{u.phone}</td>
                                    <td>{u.is_trader ? <FontAwesomeIcon icon={faCheck} style={{ color: "#07f184" }} /> : <FontAwesomeIcon icon={faTimes} style={{ color: "#fa3706" }} />}</td>
                                    <td>{u.is_active ? <FontAwesomeIcon icon={faCheck} style={{ color: "#07f184" }} /> : <FontAwesomeIcon icon={faTimes} style={{ color: "#fa3706" }} />}</td>
                                    <td>{new Date(u.created_at).toLocaleDateString('fa-IR')}</td>
                                </tr>
                            )
                        })

                    )}



                </tbody>
            </Table>

            <div>
                {
                    Array(Math.ceil(usersCount / PAGE_SIZE)).fill(null).map((item, index) => (
                        <button
                            onClick={() => setPage(index + 1)}
                            style={{
                                backgroundColor: (page === index + 1) ? 'orange' : 'white',
                                border: '1px solid #999',
                                margin: 5,
                                borderRadius: 5,
                                padding: 10,
                            }}
                        >{index + 1}</button>
                    ))
                }
            </div>

            {/* <Pagination >
                <Pagination.First />
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Ellipsis />

                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item disabled>{14}</Pagination.Item>

                <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next />
                <Pagination.Last />
            </Pagination> */}

        </div >
    )
}


export default Users;

// const StyledSearchbox = styled.select`
//     width: 80px;
// `
// const StyledForm = styled.input`
// width: 80px;

// `
const ActionsHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    text-align: center;
`