import { fetchAPI } from './';
import { PAGE_SIZE } from '../constants/api';

export const listUsers = async (searchQuery, page, options) => {
    let url = `admin/users/?search=${searchQuery}&limit=${PAGE_SIZE}&offset=${(page - 1) * PAGE_SIZE}`

    if (options) {
        url += `&is_trader=${options.is_trader}`
    }

    return await fetchAPI(url, 'GET')
}




export const retrieveUser = async (username) => {
    const url = `admin/users/${username}/`
    return await fetchAPI(url, 'GET')
}

export const portfolioUser = async (username) => {
    const url = `admin/users/${username}/portfolios/`
    return await fetchAPI(url, 'GET')
}

export const retrievePortfolio = async (username,id) => {
    const url = `admin/users/${username}/portfolios/${id}/`
    return await fetchAPI(url, 'GET')
}
