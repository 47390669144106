import React, { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { InputGroup, Table, FormControl, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import { listInventories, trade } from "../../api/inventories";
import {
  getKucoinInstrumentsPrices,
  getUSDTPrice,
} from "../../api/instruments";

const Inventories = (props) => {
  const [usdt, setUSDT] = useState(0);
  const [kucoinPrices, setKucoinPrices] = useState({});
  const [inventories, setInventories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const { lastJsonMessage } = useWebSocket(
    "wss://api.pabla.ir/ws/v2/instruments/prices/"
  );

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage.type === "INSTRUMENTS_PRICES") {
      const payload = JSON.parse(lastJsonMessage.payload);
      setKucoinPrices(payload.instruments);
      setUSDT(payload.dollar);
    }
  }, [lastJsonMessage]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    const filteredInventories = inventories.filter((i) =>
      i.symbol.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchResults(filteredInventories);
  };

  useEffect(() => {
    setLoading(true);
    listInventories().then((res) => {
      if (res.status === 200) {
        const data = res.data.sort((a, b) => b.pabla - a.pabla);
        setInventories(data);
        setSearchResults(data);
        setKucoinPrices(data.reduce((acc, curr) => {
          return { ...acc, [curr.symbol]: {s: curr.info?.sell_price || 0} };
        }, {}));
        setLoading(false);
      }
    });
  }, []);

  console.log(kucoinPrices);

  const tradeHandler = (side, instrument) => {
    const data = {
      side: side,
      size: Math.abs(instrument.kucoin - instrument.pabla).toString(),
      symbol: instrument.symbol,
    };

    trade(data).then();
  };

  const differenceColor = (instrument) => {
    if (instrument.kucoin - instrument.pabla > 0) {
      return "green";
    } else {
      return "red";
    }
  };

  return (
    <div>
      <InputGroup className='mb-3' style={{ width: "30%" }}>
        <FormControl
          placeholder='جستجو'
          aria-label='Username'
          aria-describedby='basic-addon1'
          value={searchTerm}
          onChange={handleChange}
        />
        <InputGroup.Text>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
      </InputGroup>

      <Table striped hover>
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner animation='border' variant='warning'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <thead>
              <tr>
                <th>#</th>
                <th>ارز</th>
                <th>کوکوین</th>
                <th>پابلا</th>
                <th>اختلاف</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{0}</td>
                <td>{"جمع"}</td>
                <td>{}</td>
                <td>{}</td>
                <td style={{ }}>
                  {searchResults.reduce((acc, curr) => {
                    const diff = curr.kucoin - curr.pabla;

                    return acc + (diff * parseFloat(kucoinPrices[curr.symbol]?.s));
                  }, 0).toFixed(0)} تومان
                </td>
                <td></td>
              </tr>
              {/* here! here! */}
              {searchResults.map((instrument, index) => {
                const diff = instrument.kucoin - instrument.pabla;

                return (
                  //<NegativePosition>
                  <tr key={instrument.id}>
                    <td>{index + 1}</td>
                    <td>{instrument.symbol}</td>
                    <td>{instrument.kucoin}</td>
                    <td>{instrument.pabla}</td>
                    <td style={{ color: differenceColor(instrument) }}>
                      <span dir='rtl'>
                        {diff.toFixed(4)} (
                        {Math.abs(
                          (parseFloat(diff) *
                            parseFloat(kucoinPrices[instrument.symbol]?.s))
                        ).toFixed(0)}{" "}
                        تومان)
                      </span>
                    </td>
                    <td>
                      {/* <input
                        type="text"
                        class="form-control"
                        value={inventories}
                        defaultValue={(inven.kucoin - inven.pabla).toFixed(4)}
                        aria-label="Username"
                      /> */}
                      {instrument.kucoin - instrument.pabla < 0 ? (
                        <ButtonShape
                          type='button'
                          className='btn btn-outline-success'
                          onClick={() => tradeHandler("buy", instrument)}
                        >
                          خرید
                        </ButtonShape>
                      ) : (
                        <ButtonShape
                          type='button'
                          className='btn btn-outline-danger'
                          onClick={() => tradeHandler("sell", instrument)}
                        >
                          فروش
                        </ButtonShape>
                      )}
                    </td>
                  </tr>
                  //</NegativePosition>
                );
              })}
            </tbody>
          </>
        )}
      </Table>
    </div>
  );
};

export default Inventories;

const ButtonShape = styled.button`
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  width: 100px;
`;
